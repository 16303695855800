function isBadge(item) { return item.badgeColor && item.badgeText; }
function isTimer(item) { return item.specialDiscount && item.specialDiscount.endDate && new Date(item.specialDiscount.endDate) > new Date(); }
function isSalesDeal(item) { return isBadge(item) || isTimer(item); }
function takeDealListShown(dealData, dealType, marketerId, agencyMarketerContent) {
  const tempArray = [];
  const dealList = [];
  dealType.forEach((item) => {
    if (marketerId === '') {
      tempArray.push({ name: item.name, id: item.id, list: [], sortOrder: item.sortOrder, agencyCode: item.agencyCode });
    } else {
      const showMainDeals = agencyMarketerContent?.marketerSpecificContents?.showMainDeals ?? true;
      if (!showMainDeals) {
        const listDealCategory = agencyMarketerContent?.marketerSpecificContents?.dealCategoriesToShow ?? [];
        if (listDealCategory && Array.isArray(listDealCategory)) {
          if (listDealCategory.includes(item.id)) {
            tempArray.push({ name: item.name, id: item.id, list: [], sortOrder: item.sortOrder, agencyCode: item.agencyCode });
          }
        }
      } else {
        tempArray.push({ name: item.name, id: item.id, list: [], sortOrder: item.sortOrder, agencyCode: item.agencyCode });
      }
    }
  });

  tempArray.forEach((elm) => {
    // Filter the deals matched category and isSalesDeal is a false
    const listFilteredByDealType = dealData.filter(
      (item) => (
        item.dealCategoryIds.indexOf(elm.id) > -1 && !isSalesDeal(item)
      ),
    );
    // Filter the deals matched category and deal that isSalesDeal is true
    const salesDeals = dealData.filter(
      (item) => (
        item.dealCategoryIds.indexOf(elm.id) > -1 && isSalesDeal(item)
      ),
    );

    if (listFilteredByDealType.length > 0) {
      elm.list.push(...listFilteredByDealType.map((item) => ([String(item.hotelId), [item]])));
    }

    if (salesDeals.length > 0) elm.list.push(...salesDeals.map((item) => ([String(item.hotelId), [item]])));
  });

  // sort part
  tempArray.forEach((item) => {
    item.list.sort((a, b) => {
      if (isSalesDeal(a[1][0]) && !isSalesDeal(b[1][0])) {
        return -1;
      } else if (!isSalesDeal(a[1][0]) && isSalesDeal(b[1][0])) {
        return 1;
      }
      return 0;
    });
    item.list.sort((a, b) => Number(a[1][0].totalAfterDiscount) - Number(b[1][0].totalAfterDiscount));
    item.list.sort((a, b) => {
      if (a[1][0].priority > b[1][0].priority) {
        return -1;
      } else if (a[1][0].priority < b[1][0].priority) {
        return 1;
      }
      return 0;
    });
    item.list.sort((a, b) => {
      if (a[1][0].dealGenerationSessionGuid === null && b[1][0].dealGenerationSessionGuid !== null) {
        return -1;
      } else if (a[1][0].dealGenerationSessionGuid !== null && b[1][0].dealGenerationSessionGuid === null) {
        return 1;
      }
      return 0;
    });
    item.list.sort((a, b) => {
      if (a[1][0].extendedProperties.sourceName === 'ATLANTIS' && b[1][0].extendedProperties.sourceName !== 'ATLANTIS') {
        return -1;
      } else if (a[1][0].extendedProperties.sourceName !== 'ATLANTIS' && b[1][0].extendedProperties.sourceName === 'ATLANTIS') {
        return 1;
      }
      return 0;
    });
    if (item.list.length > 0) dealList.push(item);
  });
  dealList.sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));
  if (marketerId !== undefined && marketerId !== null && marketerId !== '') {
    const idx = dealList.findIndex((a) => a.agencyCode === marketerId);
    if (idx !== -1) {
      const agencyTab = dealList.splice(idx, 1);
      dealList.splice(0, 0, agencyTab[0]);
    }
  }
  return dealList;
}

export {
  isBadge,
  isTimer,
  isSalesDeal,
  takeDealListShown,
};
