import { mapGetters } from 'vuex';
import { takeDealListShown } from '@/controllers/controllerHome';

export default {
  name: 'TemplateLesiurtabs',
  props: {
    dealData: {
      type: Array,
      default: () => [],
    },
    dealType: {
      type: Array,
      default: () => [],
    },
    banner: {
      type: Array,
      default: () => [],
    },
    bannerHref: {
      type: String,
      default: '',
    },
    showBanner: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filterConditions: [],
      sortCondition: [],
      dealListItemNew: [],
      sortButtonList: [
        'priceLTH',
        'priceHTL',
      ],
      mobileSortButtonList: [
        'priceLTH',
        'priceHTL',
      ],
      iActive: 0,
    };
  },
  computed: {
    ...mapGetters({
      marketerId: 'GET_MARKETER_ID',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isLandingChain: 'GET_IS_LANDING_PAGE_CHAIN',
      isRTLPage: 'GET_IS_RLT_PAGE',
      // dealList: 'GET_DEALS_SHOWN',
    }),
    dealList() {
      const data = takeDealListShown(this.dealData, this.dealType, this.marketerId, this.marketerAgencyContent);
      return data;
    },
    bannerImage() {
      const { banner } = this;
      if (banner.length === 0) return '';
      return this.banner[0].replace(/ /g, '%20');
    },
    getFilterConditions() {
      const { filterConditions } = this;
      return JSON.stringify(filterConditions);
    },
    activeIndex() {
      // const { dealList } = this;
      // const { query } = this.$route;
      // if (!dealList || !query.dealType) return 0;

      // const inx = dealList.findIndex((item) => (item.name === query.dealType));

      return (this.iActive === -1) ? 0 : this.iActive;
    },
    priceList() {
      const { dealList, iActive } = this;
      if (!dealList || !dealList.length || iActive > -1) return [];

      const list = [...new Set(dealList[iActive].list.map((item) => (item[1][0].totalAfterDiscount.toFixed(0))))];
      return [...new Set(list)].sort((a, b) => a - b);
    },
    filterListOptions() {
      const filterListOptionsTmp = {};

      if (this.priceList.length > 1) {
        const minPrice = Number(this.priceList[0]);
        const maxPrice = Number(this.priceList[this.priceList.length - 1]);

        const priceFilter = {
          kind: 'slider',
          title: 'price',
          value: [minPrice, maxPrice],
          min: minPrice,
          max: maxPrice,
          marks: this.priceList,
          symbol: 'NIS',
        };

        filterListOptionsTmp.priceSlider = priceFilter;
      } else {
        filterListOptionsTmp.priceSlider = null;
      }

      filterListOptionsTmp.hotelBasis = this.hotelBasisFilterOption;
      filterListOptionsTmp.hotelName = this.hotelNameFilterOption;
      filterListOptionsTmp.hotelDest = this.hotelDestFilterOption;
      return filterListOptionsTmp;
    },
    hotelBasisFilterOption() {
      const { dealList, iActive } = this;
      if (!dealList || !dealList.length) return null;
      let noneDupBasisNames = [...new Set(dealList[iActive].list.map((item) => (item[1][0].basisName)))];
      noneDupBasisNames = noneDupBasisNames.filter((el) => el != null);
      const hotelBasis = [...noneDupBasisNames.map((item) => ({ label: item }))];

      return {
        kind: 'hotel-basis',
        title: this.$t('search-result.hosting-base'),
        subItems: hotelBasis,
      };
    },
    hotelNameFilterOption() {
      const { dealList, iActive } = this;
      if (!dealList || !dealList.length) return null;
      const noneDupHotelNames = [...new Set(dealList[iActive].list.map((item) => (item[1][0].hotelName)).sort((a, b) => (a < b ? -1 : 1)))];
      const hotelNames = [...noneDupHotelNames.map((item) => ({ label: item }))];

      return {
        kind: 'hotel-name',
        title: this.$t('search-result.hotels'),
        subItems: hotelNames,
      };
    },
    hotelDestFilterOption() {
      const { dealList, iActive } = this;
      if (!dealList || !dealList.length) return null;
      const noneDupHotelDest = [...new Set(dealList[iActive].list.map((item) => (item[1][0].destCode)))];
      const hotelDest = [...noneDupHotelDest.map((item) => ({ label: item }))];

      return {
        kind: 'hotel-dest',
        title: this.$t('search-result.city'),
        subItems: hotelDest,
      };
    },
  },
  watch: {
    dealList() {
      const { dealList } = this;
      const { query } = this.$route;
      if (!dealList || !query.dealType) return;
      this.iActive = dealList.findIndex((item) => (item.name === query.dealType));
    },
  },
  created() {
    const { dealList } = this;
    const { query } = this.$route;
    if (!dealList || !query.dealType) return;

    this.iActive = dealList.findIndex((item) => (item.name === query.dealType));
  },
  mounted() {
  },
  methods: {
    groupByKey(array, key, moreKey) {
      return array
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash;
          if (moreKey !== undefined) {
            const newKey = `${obj[key]}_${obj[moreKey]}`;
            return Object.assign(hash, { [newKey]: (hash[newKey] || []).concat(obj) });
          } else {
            return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
          }
        }, {});
    },
    async filterByOption(conditions) {
      this.filterConditions = conditions;
    },
    changeTab(index) {
      this.iActive = index;
      this.forceUpdate();
    },
    forceUpdate() {
      this.filterConditions = [];
    },
  },

};
